// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merchants-js": () => import("./../../../src/pages/merchants.js" /* webpackChunkName: "component---src-pages-merchants-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-terms-and-conditions-us-cookie-policy-js": () => import("./../../../src/pages/terms-and-conditions/us/cookie-policy.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-us-cookie-policy-js" */),
  "component---src-pages-terms-and-conditions-us-index-js": () => import("./../../../src/pages/terms-and-conditions/us/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-us-index-js" */),
  "component---src-pages-terms-and-conditions-us-privacy-policy-js": () => import("./../../../src/pages/terms-and-conditions/us/privacy-policy.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-us-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-us-tou-js": () => import("./../../../src/pages/terms-and-conditions/us/tou.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-us-tou-js" */)
}

